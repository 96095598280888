var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-tabs',{attrs:{"default-active-key":_vm.tabIndex,"animated":false},on:{"change":_vm.callback}},_vm._l((_vm.tabs),function(item){return _c('a-tab-pane',{key:item.key,attrs:{"tab":item.name}},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"item"},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('add'),expression:"'add'"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.$jump({
                  path: '/communityAdd'
                })}}},[_vm._v(" 新增渠道经理 ")])],1)]),_c('my-table',{ref:"table",refInFor:true,attrs:{"columns":_vm.columns,"data-source":item.data,"pagination":item.pagination,"loading":item.loading,"scroll":{ x: 1000, y: true }},on:{"change":item.handlePaginationChange},scopedSlots:_vm._u([{key:"type",fn:function(record){return [_vm._v(" "+_vm._s(_vm.typeText[record.lectortype])+" ")]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].status,"text":_vm.statuText[record.status].text}})]}},{key:"avatarUrl",fn:function(record){return [_c('div',{staticClass:"avatarUrl"},[_c('img',{attrs:{"src":record.avatarUrl,"alt":"avatarUrl"}})])]}},{key:"wechatAppletsQcode",fn:function(record){return [_c('div',{staticClass:"codeShow",on:{"click":function($event){return _vm.setStatusCode(record)}}},[_vm._v(" 显示推广码 ")])]}},{key:"action",fn:function(record){return [_c('div',{staticStyle:{"display":"flex","width":"400px","margin":"0 auto","height":""}},[_c('div',{staticClass:"codeShow"},[_c('router-link',{attrs:{"to":{
                    path: '/communityInfo',
                    query: { id: record.id, communityId: record.communityId }
                  }}},[_vm._v(" 更多详情 ")])],1),_c('div',{staticClass:"codeShow",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.setStatus(record.id, record.status, record.bcid)}}},[(record.status === 1)?_c('span',[_vm._v("禁用")]):_c('span',[_vm._v("启用")])]),_c('div',{staticClass:"codeShow",staticStyle:{"margin-left":"10px"}},[_c('router-link',{attrs:{"to":{
                    path: '/Supervisor',
                    query: { id: record.id, communityId: record.communityId }
                  }}},[_vm._v(" 查看渠道经理业务比例 ")])],1),_c('div',{staticClass:"codeShow",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.platform(record.id, record.isAdminRate)}}},[(record.isAdminRate === 1)?_c('span',[_vm._v("抽佣关闭")]):_c('span',[_vm._v("抽佣开启")])])])]}}],null,true)})],1)])}),1),_c('a-modal',{attrs:{"title":"推广码","footer":false},model:{value:(_vm.modalCode),callback:function ($$v) {_vm.modalCode=$$v},expression:"modalCode"}},[_c('div',{staticClass:"codeWx"},[_c('img',{attrs:{"src":_vm.urlCode}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }